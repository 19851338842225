/* these redirects are JS-based client side redirects when the app's running
intended for logic is required for a redirect (like the sensor tutorials) */
// TODO: have all landingpages and docs have a _meta.json file and a "redirect":"<url>"" field
const LandingPageRedirects: { [sourcePath: string]: string } = {
  "api-reference/notecard-api": "/api-reference/notecard-api/introduction",
  "api-reference/notehub-api": "/api-reference/notehub-api/api-introduction",
  "api-reference/datasheets": "/datasheets",

  "datasheets/application-notes": "/datasheets/application-notes/notecarrier-a-series-solar-jst-input",

  "example-apps/accelerators": "accelerators",
  "example-apps/community-projects": "community/projects",
  "example-apps/sample-apps": "/example-apps/sample-apps/sample-apps-list",

  faq: "/faq/frequently-asked-questions",

  "feather-mcus/cygnet": "/feather-mcus/cygnet/cygnet-introduction",
  "feather-mcus/cygnet/cygnet-quickstart": "/quickstart/cygnet-quickstart",
  "feather-mcus/cygnet/cygnet-datasheet": "/datasheets/cygnet-datasheet",
  "feather-mcus/swan": "/feather-mcus/swan/swan-introduction",
  "feather-mcus/swan/swan-quickstart": "/quickstart/swan-quickstart",
  "feather-mcus/swan/swan-datasheet": "/datasheets/swan-datasheet",

  "guides-and-tutorials/notecard-guides": "/guides-and-tutorials/notecard-guides/guide-listing",

  "guides-and-tutorials/notecard-guides/encrypting-data-with-the-notecard": "/guides-and-tutorials/notecard-guides/encrypting-and-decrypting-data-with-the-notecard",

  "lora/lorawan-quickstart": "/quickstart/lorawan-quickstart",
  "lora/notecard-lora-datasheet": "/datasheets/notecard-datasheet/note-lora-v2-1",

  "starnote/starnote-quickstart": "/quickstart/starnote-quickstart",
  "starnote/starnote-datasheet": "/datasheets/starnote-datasheet",

  "notecard/notecard-api-reference": "/api-reference/notecard-api",
  "notecard/notecard-walkthrough": "/notecard/notecard-walkthrough/overview",
  "notecard/notecard-walkthrough/host-firmware-update-requests": "/notehub/host-firmware-updates/host-firmware-update-requests",
  "notecard/notecard-guides": "/guides-and-tutorials/notecard-guides/guide-listing",

  "notehub/notehub-api-reference": "/api-reference/notehub-api",
  "notehub/routing-tutorial": "/guides-and-tutorials/routing-data-to-cloud",
  "notehub/host-firmware-updates": "/notehub/host-firmware-updates/host-dfu-overview",

  sparrow: "/lora/sparrow/introduction-to-sparrow",
  "sparrow/sparrow-quickstart": "/quickstart/sparrow-quickstart",
  "sparrow/sparrow-datasheet": "/datasheets/sparrow-datasheet",

  "tools-and-sdks/firmware-libraries": "/tools-and-sdks/firmware-libraries/libraries-overview",

  "tools-and-sdks/notehub-sdks": "tools-and-sdks/notehub-sdks/sdks-overview",
};

type RedirectRule = {
  contextSlug: string;
  sourceSlug: string;
  destSlug: string;
};

const rulesToPrevent404: RedirectRule[] = [
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "arduino",
    destSlug: "arduino-nano-33-ble-sense",
  },
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "esp32",
    destSlug: "adafruit-huzzah32",
  },
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "feather",
    destSlug: "adafruit-feather-m4-express",
  },
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "arduino-wiring",
    destSlug: "c-cpp-arduino-wiring",
  },
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "c-cpp",
    destSlug: "c-cpp-stm32cube",
  },
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "notecarrier-al/raspberry-pi/python",
    destSlug: "notecarrier-pi/raspberry-pi/python",
  },
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "notecarrier-af",
    destSlug: "notecarrier-f",
  },
  {
    contextSlug: "collecting-sensor-data",
    sourceSlug: "notecarrier-al",
    destSlug: "notecarrier-a",
  },
  {
    contextSlug: "routing-data-to-cloud",
    sourceSlug: "initialstate",
    destSlug: "initial-state",
  },
  {
    contextSlug: "tools-and-sdks/samples",
    sourceSlug: "product-uid",
    destSlug: "../../notehub/notehub-walkthrough/#finding-a-productuid",
  },
  {
    contextSlug: "guides-and-tutorials/notecard-guides",
    sourceSlug: "outboard-dfu",
    destSlug: "notecard-outboard-firmware-update",
  },
];

function applyRedirectIfApplicable(
  sourcePath: string,
  { contextSlug, sourceSlug, destSlug }: RedirectRule,
) {
  const re = new RegExp(`(/${contextSlug}/(.*/)?)${sourceSlug}($|[/#\\?].*)`);
  const destPath = sourcePath.replace(re, `$1${destSlug}$3`);
  return destPath;
}

const RedirectToPrevent404 = (
  sourcePath: string,
  logFunction?: (message: string) => void,
): string => {
  let destPath = sourcePath;
  rulesToPrevent404.forEach((rule) => {
    destPath = applyRedirectIfApplicable(destPath, rule);
  });

  if (destPath !== sourcePath)
    logFunction?.(`Info: redirecting ${sourcePath} to ${destPath}`);

  return destPath;
};

export { LandingPageRedirects, RedirectToPrevent404 };
