import { useEffect, useState } from "react";
import { NextRouter, useRouter } from "next/router";
import * as Sentry from "@sentry/react";
import { HtmlPageTitle } from "@components/HtmlPageTitle";
import { SiteHtmlPageTitle } from "@content/en/HtmlTitle";

// Layout
import SiteChrome from "@chrome/SiteChrome";

import { RedirectToPrevent404 } from "@config/clientRedirects";

// 404 Page
import Page404 from "@content/en/404";

const onBrowserNotServer = useEffect;

const ReportError404 = () => {
  onBrowserNotServer(() => {
    throw Error(`404: ${window.location.href}`);
  });
  return <></>;
};

export const RedirectOrShow404 = () => {
  const [isFixable, setIsFixable] = useState<
    "UNFIXABLE" | "UNKNOWN" | "FIXABLE"
  >("UNKNOWN");

  const maybeRedirect = (router: NextRouter) => {
    const oldPath = router.asPath;
    // eslint-disable-next-line no-console
    const newPath = RedirectToPrevent404(oldPath, console.info);
    if (newPath !== oldPath) {
      setIsFixable("FIXABLE");
      router.replace(newPath);
      return true;
    }
    return false;
  };

  const router = useRouter();

  useEffect(() => {
    if (maybeRedirect(router)) return;
    setIsFixable("UNFIXABLE");
  }, [router]);

  const responses = {
    UNKNOWN: <>Let&apos;s see if we can&apos;t find that page...</>,
    FIXABLE: <>Found it! One moment...</>,
    UNFIXABLE: (
      <>
        <Page404 />
        <Sentry.ErrorBoundary>
          <ReportError404 />
        </Sentry.ErrorBoundary>
      </>
    ),
  };

  return responses[isFixable];
};

const Error404 = () => (
  <SiteChrome>
    <HtmlPageTitle title={`Not Found - ${SiteHtmlPageTitle}`} />
    <RedirectOrShow404 />
  </SiteChrome>
);

export default Error404;
