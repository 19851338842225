// Theme
import theme from "@config/theme";

const Page404 = () => (
  <>
    <div className="e404">
      <h1>404</h1>
      <h2>{`{"err":"page not found"}`}</h2>
      <p>
        Sorry, we can&apos;t seem to find the page you&apos;re looking for.
        <br />
        If you typed the address, double-check the spelling.
        <br />
        If you clicked a link to get here, we&apos;re working on fixing it.
      </p>
    </div>
    <style jsx>{`
      :global(.REPL) {
        display: none !important;
      }

      .e404 h1 {
        color: ${theme.colors.notehubBlueTint};
        opacity: 0.1;
        font-family: var(--fira-code-font);
        font-size: 30vw;
        line-height: 200px;
        letter-spacing: ${theme.fonts.letterSpacing.ten};
        text-align: center;
        margin: 50px 0px -115px 0px;
      }

      .e404 h2 {
        color: ${theme.colors.gray1};
        font-size: ${theme.fonts.size.px36};
        letter-spacing: ${theme.fonts.letterSpacing.two};
        text-align: center;
        margin: 0px 0px 110px 0px;
      }

      .e404 p {
        color: ${theme.colors.gray1};
        font-weight: 500;
        line-height: 30px;
        text-align: center;
      }
    `}</style>
  </>
);

export default Page404;
